
import { Clinic } from '@/modules/clinic'
import $app from '@/plugins/modules'
import { Component, Vue } from 'vue-property-decorator'
import { IManipulation } from '../types';

@Component
export default class ResearchReport extends Vue {
  clinic = $app.module(Clinic);

  research: any = null;
  manipulations: Array<IManipulation> = [];
  loading = false;

  async load() {
    this.loading = true;
    try {
      const id = this.$route.query.ResearchId;
      this.research = await $app.get('/api/clinics/researches/' + id);
      this.manipulations = await $app.get('/api/clinics/researches/' + id + '/manipulations');
    } catch (err) {
      $app.pushError(err);
      throw err;
    }
    this.loading = false;
  }

  mounted() {
    this.load();
  }
}
